import { genFingerprint, getCartCnt, getNotiList } from '@/util/comn_func'
import axis_cstm from '@/util/axis_cstm'
import { API_ROOT, BrwsStrgKey, ResCd } from '@/util/comn_cnst'
import { mdal_stor } from '@/stor/mdal_stor'
import { user_stor } from '@/stor/user_stor'
import { i18n } from '@/lcal/i18n'
import { copyLoclToSess } from '@/util/comn_util'

const { t } = i18n.global
const parseJwt = (token) => {
  if (!token || token.length < 10) {
    return null
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  try {
    let message = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(message)
  } catch (e) {
    console.error('[ParseJwt]', e.message)
    return null
  }
}

function initComnCodes() {

  if (typeof(comn)==='undefined') {
    let prevScript = document.getElementById("comn")
    prevScript.remove()
    let newScript = document.createElement('script')
    newScript.setAttribute('id', 'comn')
    newScript.setAttribute('src', API_ROOT + 'pblc/code/comn.js')
    try {
      document.head.appendChild(newScript)
      comn = new Comn()
    } catch(e) {
      console.warn("[INIT] Couldn't load comn.js")
    }
  }

}

function initOnce() {

  // https://vitejs.dev/guide/build.html#load-error-handling
  window.addEventListener('vite:preloadError', function() {
    try {
      mdal_stor().showMainLodr()
    } catch(e) {
      console.error("[FAIL] vite:preloadError")
    } finally {
      window.location.reload() // for example, refresh the page
    }
  })

  const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
  if (!tp || tp.indexOf('$')<0) {
    genFingerprint()
  }

  copyLoclToSess()

  if (typeof(comn)==='undefined') {
    let prevScript = document.getElementById("comm")
    if (prevScript) {
      prevScript.remove()
    }
    let newScript = document.createElement('script')
    newScript.setAttribute('id', 'comm')
    newScript.setAttribute('src', API_ROOT + 'pblc/comm/code.js')
    try {
      document.head.appendChild(newScript)
      comn = new Comn()
    } catch(e) {
      console.warn("[INIT] Couldn't load comn.js")
    }
  }
}

function chckFrstAccs() {
  const frstAccs = sessionStorage.getItem(BrwsStrgKey.FIRST_SIGN_IN)
  if (frstAccs && frstAccs === 'true') {
    const dispName = sessionStorage.getItem(BrwsStrgKey.USER_NAME)
    sessionStorage.removeItem(BrwsStrgKey.FIRST_SIGN_IN)
    const tf = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
    if (tf) {
      localStorage.removeItem(BrwsStrgKey.FO_JWT)
    }
    setTimeout(function () {
      mdal_stor().toast(t('SIGNIN_WELCOM_MSG', [dispName]))
    }, 100)
  }
}

function getNowEpchSec() {
  return Math.round(new Date().getTime() / 1000)
}

function getToknExprTimeInSec() {
  const jwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
  if (!jwtStr || jwtStr.length < 9) {
    return 0
  }
  const jwt = parseJwt(jwtStr)
  if (jwt) {
    return jwt.exp - getNowEpchSec()
  } else {
    return 0
  }
}

function getToknExprTimeInMinSec() {
  const timeLeft = getToknExprTimeInSec()
  const min = Math.floor(timeLeft / 60)
  const sec = Math.round(timeLeft % 60)
  return [min, sec]
}

function rfshToknSync() {
  console.log(`[rfshToknSync]`)
  return axis_cstm()
    .patch(API_ROOT + `prvt/user/rfsh`, {
      tp: localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
    }, { timeout: 8000 }).then()
}

function rfshToknAsync() {
  axis_cstm()
    .patch(API_ROOT + `prvt/user/rfsh`, {
      tp: localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
    })
    .then((res) => {
      if (res.status===401) {
        user_stor().clerUserDataAndGoMain()
      } else if (res.data['code'] === ResCd.TOKN_EXPD) {
        user_stor().clerUserDataAndGoMain()
      } else if (res.data['code'] === ResCd.SUCCESS) {
        // rslt => accsTokn, thumUrl, dispName, cartCnt, unReadNotiCnt, notiList
        const tokn = res.data.rslt.accsTokn
        if (tokn) {
          user_stor().setTokn(tokn)
          user_stor().setCartCnt(res.data.rslt.cartCnt)
          let notiObj = {
            list: res.data.rslt.notiList,
            cnt: res.data.rslt.unReadNotiCnt
          }
          user_stor().setAncmInfo(notiObj)
          sessionStorage.setItem(BrwsStrgKey.USER_PROF_IMG, res.data.rslt.thumUrl)
          sessionStorage.setItem(BrwsStrgKey.USER_NAME, res.data.rslt.dispName)
          console.log(`[RFSH-TOKN] got new one ${getToknExprTimeInMinSec()[0]}m left`)
        } else {
          console.warn('[RFSH-TOKN] failed to get new token empty')
          // 자동 로그아웃?
        }
      } else if (res.data['code'] === ResCd.SERVER_ERR) {
        console.warn('[RFSH-TOKN] failed to get new token 500')
      }
    }).catch(e => {
      console.warn('[RFSH-TOKN] failed to get new token', e.message)
    }).finally(() => {
  })
}

async function rfshToknSyncFull() {
  const res = await rfshToknSync()
  console.log(`[RfshToknSyncFull] ${res.status}`)
  if (res.status===401) {
    user_stor().clerUserDataAndGoMain()
  } else if (res.data.code === ResCd.SUCCESS) {
    // rslt => accsTokn, thumUrl, dispName, cartCnt, unReadNotiCnt, notiList
    const tokn = res.data.rslt.accsTokn
    if (tokn) {
      user_stor().setTokn(tokn)
      user_stor().setCartCnt(res.data.rslt.cartCnt)
      const notiInfo = {
        notiList: res.data.rslt.notiList,
        unReadNotiCnt: res.data.rslt.unReadNotiCnt
      }
      user_stor().setAncmInfo(notiInfo)
      sessionStorage.setItem(BrwsStrgKey.USER_PROF_IMG, res.data.rslt.thumUrl)
      sessionStorage.setItem(BrwsStrgKey.USER_NAME, res.data.rslt.dispName)
      console.log(`[AXIS-INTRCPT] got new one ${getToknExprTimeInMinSec()[0]}m left`)
    }
  } else if (res.data.code === ResCd.TOKN_EXPD) {
    user_stor().clerUserDataAndGoMain('Signed out for inactivity')
  }
}

export {
  initOnce,
  initComnCodes,
  parseJwt,
  getNowEpchSec,
  getToknExprTimeInSec,
  getToknExprTimeInMinSec,
  rfshToknSync,
  rfshToknAsync,
  chckFrstAccs,
  rfshToknSyncFull
}
