<template>
  <!-- 전체 앱 래퍼 -->
  <div id="app-contents" class="app-contents">

    <navbar-comp />
    <router-view />

  </div>
  <!-- 전체 앱 래퍼 -->

  <img-work-detl-modal />

  <crtr-info-modal v-if='user.getCreator' :crtrId="user.getCreator" />
  <my-info-modal v-if="user.getShowMyInfo" />

  <spnr-full-block v-show="mdal.isLoading" />
  <spnr-no-block v-show="mdal.isNonBlckLodn" />

  <cnfm-modal v-if='mdal.showCnfm' />
  <alrt-modal v-if='mdal.showAlrt' />

  <tost-noti />

  <form name="dForm" method="post" target="hidn-frme" enctype="application/x-www-form-urlencoded"
        class="d-none">
    <input type="hidden" name="tp" id="tp" />
    <iframe id="hidn-frme"></iframe>
  </form>

</template>

<script setup>
import NavbarComp from '@/view/sect/NavbarComp.vue'
import CnfmModal from '@/view/pgrsAlrt/CnfmModal.vue'
import SpnrFullBlock from '@/view/pgrsAlrt/SpnrFullBlock.vue'
import TostNoti from '@/view/pgrsAlrt/TostNoti.vue'
import AlrtModal from '@/view/pgrsAlrt/AlrtModal.vue'
import CrtrInfoModal from '@/view/modal/CrtrInfoModal.vue'
import ImgWorkDetlModal from '@/view/modal/StckImgDetlModal.vue'
import { RouterView } from 'vue-router'
import { user_stor } from '@/stor/user_stor'
import { onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import MyInfoModal from '@/view/modal/MyInfoModal.vue'
import { mdal_stor } from '@/stor/mdal_stor'
import { chckFrstAccs, initOnce } from '@/util/auth_func'
import { getCartCnt, getNotiList } from '@/util/comn_func'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'
import { cnst_stor } from '@/stor/cnst_stor'

const mdal = mdal_stor()
const user = user_stor()
const cnst = cnst_stor()
const model = reactive({
  init: false,
  grupId: null,
  itemId: null,
  rgstStep: null,
  imgModal: null,
  destroy: null,
  itvlId: -1,
  showImgDetlPopUp: null,
})

watch(() => mdal.showImgDetlPopUp
  , (newParam) => {
  console.log('[WATCH]', JSON.stringify(newParam))
    model.showImgDetlPopUp = newParam
  })

onMounted(() => {

  if (model.init === false) {
    initOnce()
    model.init = true
  }

  if (user.isSignedIn) {
    chckFrstAccs()
    if (model.itvlId === -1) {
      model.itvlId = setInterval(
        user.chckToknExprThenSignOut
        , (60 * 1000))
    }
    getCartCnt()
    getNotiList()
  }
  document.addEventListener('scroll', cnst.setOnScroll)
})

onBeforeUnmount(() => {
  if (model.itvlId && model.itvlId > -1) {
    clearInterval(model.itvlId)
  }
  document.removeEventListener('scroll', cnst.setOnScroll)
})
</script>
