import { defineStore } from 'pinia'

type ComnCode = {
  code: string,
  name: string,
  valu: string
}

const INIT_NVBR_Y_POS = 0
const INIT_SRCH_Y_POS = 16
const TOP_NVBR_Y_POS = -50
const BTOM_SRCH_Y_POS = -75

export const cnst_stor = defineStore({
	id: 'constant',
	state: () => ({
    comnCode: comn?comn.getComnCode():null,
    ctryCode: comn?comn.getCtryCode():null,
    ctgrCode: comn?comn.getCtgrCode():null,
    stylCode: comn?comn.getStylCode():null,
    bgndImgs: comn?comn.getBgndImgs():null,
    tnslSrcCode: comn?comn.getTnslSrcCode():null,
		COMPANY_NAME: ["Generated", "Bitflow"],
    COMPANY_NAME_SUFFIX: ", Inc.",
		COMPANY_ADDR: ["651 N Broad St, Middletown, DE, USA", "Dongdaegu-ro, Dong-gu, Daegu, KR"],
    scrlWrap: null,
    initY: 0,
    scrlY: 0,
    prevY: 0,
    prevSrchBarY: BTOM_SRCH_Y_POS,
    srchBarY: BTOM_SRCH_Y_POS,
    prevNavBarY: INIT_NVBR_Y_POS,
    navBarY: INIT_NVBR_Y_POS,
	}),
	getters: {
    getBgndImgs: (state) => {
      return state.bgndImgs
    },
    getTnslSrcCode: (state) => {
      return state.tnslSrcCode
    },
		getCompanyName: (state) => {
			const loc = sessionStorage.getItem('tl')
			let idx = 0
			if (loc && loc.toUpperCase()==='UK') {
				idx = 1
			}
			return state.COMPANY_NAME[idx]
		},
		getCompanyAddr: (state) => {
			return state.COMPANY_ADDR[0]
		},
    getStyleCode: (state) => {
      return state.stylCode
    },
		getArtCategories: (state) => {
			return state.ctgrCode
		},
		getAllCodes: (state) => {
			return state.comnCode
		},
		getCountries: (state) => {
			return state.ctryCode
		},
    getScrlY: (state) => {
      return state.scrlY
    },
	},
	actions: {
    getCtryName(cc: string) {
      if (this.ctryCode && this.ctryCode.length > 0) {
        for (const item of this.ctryCode) {
          if (item.iso2===cc) {
            return item.name
          }
        }
      }
      return null
    },
		getCodeGrup(codeGrup: string) {
      if (this.comnCode) {
			  return this.comnCode[codeGrup]
      } else {
        return null
      }
		},
		getCodeName(grupCode: string, code: string) {
			let ret = ''
      this.getCodeGrup(grupCode).forEach((it: ComnCode) => {
				if (it.code === code) {
					ret = it.name
				}
			})
			return ret
		},
		getCodeValu(grupCode: string, code: string) {
			let ret = ''
      this.getCodeGrup(grupCode).forEach(function (it: ComnCode) {
				if (it.code === code) {
					ret = it.valu
				}
			})
			return ret
		},
		getGenToolModlNm(code: string, name: string) {
			if (name && name.length > 0) {
				return name
			}
			if (!code || code.length < 5) { return '' }
			return this.getCodeName(code.substring(0, 4), code)
		},
    setOnScrollForStockImages(scrollPos) {
      this.scrlY = scrollPos
      this.updtSrchY(scrollPos)
      this.updtNvbrY(scrollPos)
    },
    setOnScroll(e) {
      this.scrlY = e.srcElement.scrollTop
      this.updtSrchY(this.scrlY)
      this.updtNvbrY(this.scrlY)
    },
    initSrchBarY() {
      this.prevSrchBarY = INIT_SRCH_Y_POS
      this.srchBarY = INIT_SRCH_Y_POS
    },
    initNavBarY() {
      this.prevNavBarY = INIT_NVBR_Y_POS
      this.navBarY = INIT_NVBR_Y_POS
    },
    updtSrchY(val: number) {
      const dy = this.prevSrchBarY - val
      // if dy < 0 down
      if (dy > 0) {
        // scroll up
        if (this.srchBarY < INIT_SRCH_Y_POS) {
          this.srchBarY += dy
          if (this.srchBarY > INIT_SRCH_Y_POS) {
            this.srchBarY = INIT_SRCH_Y_POS
          }
        }
      } else if (dy < 0) {
        // scroll down
        if (this.srchBarY > BTOM_SRCH_Y_POS) {
          this.srchBarY += dy
          if (this.srchBarY > INIT_SRCH_Y_POS) {
            this.srchBarY = INIT_SRCH_Y_POS
          }
        }
      }
      this.prevSrchBarY = val
    },
    updtNvbrY(val: number) {
      const dy = this.prevNavBarY - val
      // if dy < 0 down
      if (dy < 0) {
        // scroll down
        if (this.navBarY > TOP_NVBR_Y_POS) {
          this.navBarY = this.navBarY + dy
          if (this.navBarY < TOP_NVBR_Y_POS) {
            this.navBarY = TOP_NVBR_Y_POS
          }
        }
      } else if (dy > 0) {
        // scroll up
        if (this.navBarY < INIT_NVBR_Y_POS) {
          this.navBarY = this.navBarY + dy
          if (this.navBarY > INIT_NVBR_Y_POS) {
            this.navBarY = INIT_NVBR_Y_POS
          }
        }
      }
      this.prevNavBarY = val
    }
	}
})

export { INIT_SRCH_Y_POS, INIT_NVBR_Y_POS }